import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import SunCalc from "suncalc";

const ScheduleFormDateandTime = ({
  startTime,
  setStartDate,
  startDate,
  setStartTime,
  endTime,
  setEndTime,
  endDate,
  setEndDate,
  useSunset,
  setUseSunset,
  useSunrise,
  setUseSunrise,
}) => {
  const [sunsetOptions, setSunsetOptions] = useState([]);
  const [sunriseOptions, setSunriseOptions] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [startDropdownOption, setStartDropdownOption] = useState("Manual");
  const [endDropdownOption, setEndDropdownOption] = useState("Manual");
  const timezone = moment.tz.guess(); // Get the user's timezone

  // Get user's geolocation (latitude and longitude)
  useEffect(() => {
    const getGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error fetching geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getGeolocation();
  }, []);

  // Calculate sunset and sunrise options (-60 to +60 minutes) based on the user's location
  useEffect(() => {
    if (latitude && longitude) {
      const calculateSunEventOptions = () => {
        const now = new Date();
        const times = SunCalc.getTimes(now, latitude, longitude); // Get sunrise and sunset times for the current day and location

        // Convert times to the user's timezone
        const sunset = moment(times.sunset).tz(timezone);
        const sunrise = moment(times.sunrise).tz(timezone);

        // Generate sunset options (-60 to +60 minutes) in 10-minute intervals
        const sunsetOptionsArray = [];
        for (let i = -60; i <= 60; i += 10) {
          const adjustedTime = sunset.clone().add(i, "minutes");
          sunsetOptionsArray.push({
            label: `Sunset ${i >= 0 ? `+${i}min` : `${i}min`}`,
            value: adjustedTime.format("HH:mm"),
          });
        }
        setSunsetOptions(sunsetOptionsArray);

        // Generate sunrise options (-60 to +60 minutes) in 10-minute intervals
        const sunriseOptionsArray = [];
        for (let i = -60; i <= 60; i += 10) {
          const adjustedTime = sunrise.clone().add(i, "minutes");
          sunriseOptionsArray.push({
            label: `Sunrise ${i >= 0 ? `+${i}min` : `${i}min`}`,
            value: adjustedTime.format("HH:mm"),
          });
        }
        setSunriseOptions(sunriseOptionsArray);
      };

      calculateSunEventOptions();
    }
  }, [latitude, longitude, timezone]);

  const handleStartDropdownChange = (event) => {
    const selectedOption = event.target.value;
    setStartDropdownOption(selectedOption);
    setUseSunset(selectedOption === "Sunset");
    setUseSunrise(selectedOption === "Sunrise");
  };

  const handleEndDropdownChange = (event) => {
    const selectedOption = event.target.value;
    setEndDropdownOption(selectedOption);
    setUseSunrise(selectedOption === "Sunrise");
    setUseSunset(selectedOption === "Sunset");
  };

  return (
    <>
      {/* Start Date & Time with Sunset Option */}
      <Form.Group as={Row} controlId="formStartDateTime" className="mb-3">
        <Form.Label column sm={2}>
          Start Date & Time
        </Form.Label>
        <Col sm={2} style={{ minWidth: "190px" }}>
          <DatePicker
            selected={startDate}
            className="input-field"
            onChange={(date) => setStartDate(date)}
            dateFormat="MM/dd/yyyy"
            customInput={
              <Form.Control
                type="text"
                value={moment(startDate).format("MM/DD/YYYY")}
                readOnly
                required
              />
            }
          />
        </Col>
        <Col sm={2} style={{ minWidth: "190px" }}>
          {startDropdownOption === "Manual" && (
            <Form.Control
              type="time"
              className="input-field"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          )}
          {startDropdownOption === "Sunset" && (
            <Form.Control
              as="select"
              className="form-select"
              onChange={(e) => setStartTime(e.target.value)}
            >
              {sunsetOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          )}
          {startDropdownOption === "Sunrise" && (
            <Form.Control
              as="select"
              className="form-select"
              onChange={(e) => setStartTime(e.target.value)}
            >
              {sunriseOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
        <Col sm={2} style={{ minWidth: "190px" }}>
          <Form.Control
            as="select"
            value={startDropdownOption}
            onChange={handleStartDropdownChange}
            className="form-select"
          >
            <option value="Manual">Manual</option>
            <option value="Sunset">Sunset</option>
            <option value="Sunrise">Sunrise</option>
          </Form.Control>
        </Col>
      </Form.Group>

      {/* End Date & Time with Sunrise Option */}
      <Form.Group as={Row} controlId="formEndDateTime" className="mb-3">
        <Form.Label column sm={2}>
          End Date & Time
        </Form.Label>
        <Col sm={2} style={{ minWidth: "190px" }}>
          <DatePicker
            selected={endDate}
            className="input-field"
            onChange={(date) => setEndDate(date)}
            dateFormat="MM/dd/yyyy"
            customInput={
              <Form.Control
                type="text"
                value={moment(endDate).format("MM/DD/YYYY")}
                readOnly
                required
              />
            }
          />
        </Col>
        <Col sm={2} style={{ minWidth: "190px" }}>
          {endDropdownOption === "Manual" && (
            <Form.Control
              type="time"
              className="input-field"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          )}
          {endDropdownOption === "Sunset" && (
            <Form.Control
              as="select"
              className="form-select"
              onChange={(e) => setEndTime(e.target.value)}
            >
              {sunsetOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          )}
          {endDropdownOption === "Sunrise" && (
            <Form.Control
              as="select"
              className="form-select"
              onChange={(e) => setEndTime(e.target.value)}
            >
              {sunriseOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
        <Col sm={2} style={{ minWidth: "190px" }}>
          <Form.Control
            as="select"
            value={endDropdownOption}
            onChange={handleEndDropdownChange}
            className="form-select"
          >
            <option value="Manual">Manual</option>
            <option value="Sunset">Sunset</option>
            <option value="Sunrise">Sunrise</option>
          </Form.Control>
        </Col>
      </Form.Group>
    </>
  );
};

export default ScheduleFormDateandTime;
