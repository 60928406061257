import React, { useState } from "react";
import axios from "axios";
import { Container, Table, Button, Modal, Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";

const ScheduleList = ({ schedules, iotName, fetchSchedules }) => {
  const location = useLocation();
  const fieldName = location.state?.fieldInfo.name;
  const userTimeZone = moment.tz.guess(); // Guess the user's time zone
  const [showEditModal, setShowEditModal] = useState(false);
  const [editSchedule, setEditSchedule] = useState({});

  const handleEdit = (schedule) => {
    setEditSchedule(schedule);
    setShowEditModal(true);
  };

  const handleDelete = async (scheduleId) => {
    try {
      await axios.delete(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/delete-schedule`,
        {
          data: { iotName, ScheduleID: scheduleId },
        }
      );
      fetchSchedules(); // Reload schedules after deleting one
    } catch (error) {
      console.error("Error deleting schedule:", error);
    }
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/update-schedule`,
        editSchedule
      );
      fetchSchedules(); // Reload schedules after editing one
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating schedule:", error);
    }
  };

  const formatEpochToLocal = (epochTime) => {
    return moment.unix(epochTime).tz(userTimeZone).format("YYYY-MM-DD HH:mm");
  };

  if (schedules < 1) {
    return <></>;
  }
  console.log(schedules);

  return (
    <Card className="scheduler-card">
      {/* Schedule Table */}
      <Table className="table-custom" responsive="md">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date/Time</th>
            <th>End Date/Time</th>
            <th>Area</th>
            <th>Action</th>
            <th>Repeat</th>
            <th>Repeat Days</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {schedules.map((schedule, index) => (
            <tr key={index} className="table-row-underline">
              <td>{schedule.Name}</td>
              <td>{formatEpochToLocal(schedule.StartDate)}</td>
              <td>{formatEpochToLocal(schedule.EndDate)}</td>
              <td>{schedule.SelectedArea}</td>
              <td>{schedule.SelectedAction}</td>
              <td>{schedule.Repeat ? "Yes" : "No"}</td>
              <td>
                {schedule.RepeatDays ? schedule.RepeatDays.join(", ") : ""}
              </td>
              <td>
                <Button
                  variant="outline-danger"
                  className="remove-button"
                  onClick={() => handleDelete(schedule.ScheduleID)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit Schedule Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editScheduleName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={editSchedule.Name || ""}
                onChange={(e) =>
                  setEditSchedule({ ...editSchedule, Name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="editScheduleStartDateTime">
              <Form.Label>Start Date/Time</Form.Label>
              <DatePicker
                selected={moment.unix(editSchedule.StartDate).toDate()}
                onChange={(date) =>
                  setEditSchedule({
                    ...editSchedule,
                    StartDate: moment(date).unix(),
                  })
                }
                showTimeSelect
                dateFormat="MM/dd/yyyy HH:mm"
                customInput={
                  <Form.Control
                    type="text"
                    value={moment
                      .unix(editSchedule.StartDate)
                      .tz(userTimeZone)
                      .format("MM/DD/YYYY HH:mm")}
                    readOnly
                  />
                }
              />
            </Form.Group>

            <Form.Group controlId="editScheduleEndDateTime">
              <Form.Label>End Date/Time</Form.Label>
              <DatePicker
                selected={moment.unix(editSchedule.EndDate).toDate()}
                onChange={(date) =>
                  setEditSchedule({
                    ...editSchedule,
                    EndDate: moment(date).unix(),
                  })
                }
                showTimeSelect
                dateFormat="MM/dd/yyyy HH:mm"
                customInput={
                  <Form.Control
                    type="text"
                    value={moment
                      .unix(editSchedule.EndDate)
                      .tz(userTimeZone)
                      .format("MM/DD/YYYY HH:mm")}
                    readOnly
                  />
                }
              />
            </Form.Group>

            <Form.Group controlId="editScheduleArea">
              <Form.Label>Area</Form.Label>
              <Form.Control
                type="text"
                value={editSchedule.SelectedArea || ""}
                onChange={(e) =>
                  setEditSchedule({
                    ...editSchedule,
                    SelectedArea: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="editScheduleAction">
              <Form.Label>Action</Form.Label>
              <Form.Control
                type="text"
                value={editSchedule.SelectedAction || ""}
                onChange={(e) =>
                  setEditSchedule({
                    ...editSchedule,
                    SelectedAction: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="editScheduleRepeat">
              <Form.Label>Repeat</Form.Label>
              <Form.Check
                type="checkbox"
                label="Repeat"
                checked={editSchedule.Repeat || false}
                onChange={(e) =>
                  setEditSchedule({ ...editSchedule, Repeat: e.target.checked })
                }
              />
            </Form.Group>

            {editSchedule.Repeat && (
              <Form.Group controlId="editScheduleRepeatDays">
                <Form.Label>Repeat Days</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    editSchedule.RepeatDays
                      ? editSchedule.RepeatDays.join(", ")
                      : ""
                  }
                  onChange={(e) =>
                    setEditSchedule({
                      ...editSchedule,
                      RepeatDays: e.target.value.split(", "),
                    })
                  }
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default ScheduleList;
